<template>
  <div class="content">
    <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh"> -->
    <van-empty v-if="hasNodata" image="error" description="暂无数据" />
    <van-list
      v-else
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="item" v-for="item in list" :key="item.id">
        <div>
          <label for="">设备id：</label> <span>{{ item.deviceId }}</span>
        </div>
        <div style="padding-top: 10px">
          <label for="">安装名称：</label> <span>{{ item.name }}</span>
        </div>
        <div style="padding-top: 10px">
          <label for="">安装位置：</label> <span>{{ item.location }}</span>
        </div>
        <div style="padding-top: 10px">
          <label for="">安装时间：</label>
          <span>{{ item.createdAt | formatDate }}</span>
        </div>
        <div style="padding-top: 10px"><label for="">安装图片：</label></div>
        <div class="image">
          <van-image
            width="100%"
            height="200px"
            fit="cover"
            :src="item.photos"
          />
        </div>
      </div>
    </van-list>
    <!-- </van-pull-refresh> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      size: 20,
      list: [],
      loading: false,
      finished: false,
      hasNodata: false,
    };
  },
  created() {},
  computed: {},
  filters: {
    formatDate: function (value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
  },
  methods: {
    onLoad() {
      this.getList();
    },
    getList() {
      this.http
        .get("/gas/device/list", {
          params: {
            companyId: localStorage.getItem("companyId"),
            page: this.page,
            size: this.size,
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.loading = false;

            this.list = this.list.concat(res.data.data.list);
            if (!res.data.data.hasNextPage) {
              this.finished = true;
            } else {
              this.page++;
              this.finished = false
            }
            if (this.page == 1 && this.list.length == 0) {
              this.hasNodata = true;
            } else {
              this.hasNodata = false;
            }
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  height: 100%;
  width: 100%;
  background-color: #f2f2f2;
  overflow: auto;
}
// van-list {
//   width: 100%;
//   height: 100%;
// }
.item {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  margin: 20px auto;
  width: 90%;
  background-color: white;
  border-radius: 10px;
  color: #333;
  font-size: 1.3em;
}
</style>
